import axios from 'axios'
import {apiBaseUrl} from '@/environment/environment'
import toasted from "../../utilities/toasted";
/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
    baseURL: apiBaseUrl
}

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config)

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
    /** add auth token */
    return config
}

const loggerInterceptor = config => {
    /** Add logging here */
    return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(loggerInterceptor)

// Add a request interceptor
httpClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        switch (error.response.status) {
            case 422: {
                let errors = Object.values(error.response.data.errors);
                errors = errors.flat();
                errors.forEach((value) => {
                    toasted.failed(value.toString());
                });
            }
                break;
            case 400:{
                    toasted.failed(error.response.data.message);
                };
                break;
            default: {
                toasted.failed('حدث خطأ ما, يرجى المحاولة لاحقاً');
                break;
            }
        }
        return Promise.reject(error)
    }

)

    

export {httpClient}
